import { BookingObject } from "../BookingModel";

export function normalizeBookings (bookings: []): any[] {
    // for simplicity we will first exchange debet and credit for all non memorials:
    function normalizeNonMemorial (b: []): any[] {
        return b.filter((el: any) => !el.isMemorial).map((el) => {
            const elCopy = JSON.parse(JSON.stringify(el)); //deep clone,  https://stackoverflow.com/a/122704/964064
            const temp = elCopy.amountDebet;
            elCopy.amountDebet = elCopy.amountCredit;
            elCopy.amountCredit = temp;
            return elCopy
        })
    };
    const normalizedNonMemoBookings: any[] = normalizeNonMemorial(bookings as []);
    const memoBookings: any[] = bookings.filter((el: any) => el.isMemorial);
    return normalizedNonMemoBookings.concat(memoBookings);
}
