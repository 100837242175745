import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import BookingForm from "../BookingForm/BookingForm";
import { BookingInterface, BookingObject } from "../BookingModel";
import { toast } from "react-toastify";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import { useAddNewBookingMutation } from "../../../app/apiService";
import BookingFormRow from "../BookingForm/BookingFormRow";
import { FC, useEffect, useState } from "react";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { RootState } from "../../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
interface Props {
  onCancel: () => void;
}
const BookingCreateRow: FC<Props> = ({ onCancel }) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const [data, setData] = useState<BookingInterface>({
    date: "",
    description: "",
    projectNumber: "",
    accountNo: "",
    amountCredit: "",
    amountDebet: "",
    bookingHeaderId: "",
  });
  const [addNewBooking, { isLoading }] = useAddNewBookingMutation();
  const navigate = useNavigate();
  const intl = useIntl();
  const onFormSubmit = (
    values: BookingInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    if (!values.diaryNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_DIARY_WARNING" }));
      setSubmitting(false);
      return;
    }
    if (!values.bookingYearsNo) {
      toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" }));
      setSubmitting(false);
      return;
    }

    if (
      (values?.date &&
        moment(values?.date).format("yyyy-MM-DD") >
          moment(selectedBookingYear.toDate).format("yyyy-MM-DD")) ||
      (values?.date &&
        moment(values?.date).format("yyyy-MM-DD") <
          moment(selectedBookingYear.fromDate).format("yyyy-MM-DD"))
    ) {
      toast.warn(intl.formatMessage({ id: "BOOKING.DATE_WARNING" }));
      setSubmitting(false);
      return;
    }
    addNewBooking({ ...values })
      .unwrap()
      .then(() => {
        setSubmitting(true);
        onCancel();
        toast.success(intl.formatMessage({ id: "BOOKING.CREATED" }));
      })
      .catch((err: any) => {
        setSubmitting(false);
        if (err.data?.code === 1101) {
          toast.error(
            intl.formatMessage({ id: "BOOKING.CREATED_FAILED_ACCOUNT" })
          );
        } else {
          toast.error(intl.formatMessage({ id: "BOOKING.CREATED_FAILED" }));
        }
      });
  };
  useEffect(() => {
    setData({
      date: moment(selectedBookingYear?.fromDate).format("yyyy-MM-DD"),
      description: "",
      projectNumber: "",
      accountNo: "",
      amountCredit: "",
      amountDebet: "",
      bookingHeaderId: "",
    });
  }, [selectedBookingYear?.fromDate]);
  return (
    <BookingFormRow
      data={data}
      onCancel={onCancel}
      formSubmitted={onFormSubmit}
    />
  );
};

export default BookingCreateRow;
