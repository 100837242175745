import React from "react";
import {
  CoaUIProvider,
  CoaContextInterface,
} from "../features/chartOfAccounts/CoaUiContext";
import { Route, Routes, useNavigate } from "react-router";
import { ProtectedRoute } from "../routing/ProtectedRoute";
import CoaCreate from "../features/chartOfAccounts/CoaCreate/CoaCreate";
import CoaEdit from "../features/chartOfAccounts/CoaEdit/CoaEdit";
import CoaCard from "../features/chartOfAccounts/CoaCard";
import { ParamsHOC } from "../_panel/helpers/components/ParamsHOC";
import CoaDelete from "../features/chartOfAccounts/CoaDelete/CoaDelete";

const ChartAccountPage = () => {
  const navigate = useNavigate();
  const coaUiEvent: CoaContextInterface = {
    deleteCoaClicked: (id) => {
      navigate("/general-ledger/chart-of-accounts/" + id + "/delete");
    },
    newCoaClicked: () => {
      navigate("/general-ledger/chart-of-accounts/new-coa");
    },
    editCoaClicked: (id) => {
      navigate("/general-ledger/chart-of-accounts/" + id + "/edit");
    },
    fetchCoa: false,
  };
  return (
    <CoaUIProvider CoaUIEvents={coaUiEvent}>
      <Routes>
        <Route
          path="new-coa/*"
          element={
            <ProtectedRoute>
              <CoaCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <CoaEdit />
            </ProtectedRoute>
          }
        />
        <Route element={<CoaCard />} path="*">
          <Route
            path=":id/delete"
            element={
              <ParamsHOC>
                {(match: { [key: string]: string }) => (
                  <CoaDelete
                    show={match != null}
                    id={match?.id}
                    onHide={() => {
                      navigate("/general-ledger/chart-of-accounts");
                    }}
                  />
                )}
              </ParamsHOC>
            }
          />
        </Route>
      </Routes>
    </CoaUIProvider>
  );
};

export default ChartAccountPage;
