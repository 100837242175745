import React from "react";
import { useIntl } from "react-intl";

const GenerateBalanceUltimoHeader = () => {
  const intl = useIntl();
  return (
    <div className="row g-2 align-items-center">
      <div className="col">
        {/* Page pre-title */}
        <h2 className="page-title">
          {intl.formatMessage({ id: "OVERVIEWS.GENERATE_BALANCE_ULTIMO" })}
        </h2>
      </div>
      {/* Page title actions */}
      <div className="col-auto ms-auto d-print-none">
        <div className="btn-list"></div>
      </div>
    </div>
  );
};

export default GenerateBalanceUltimoHeader;
