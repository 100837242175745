import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import * as yup from "yup";

import moment from "moment";
import { IntlShape, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import FormikInputComponent from "../../../_panel/partials/formik/FormikInputComponent";
import { RootState } from "../../../app/RootReducer";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import {
  useGetBanksQuery,
  useImportBankDataMutation,
} from "../../../app/apiService";
import { getFieldClassName } from "../../../common/Utils";
import { CompnayDataInterface, ImportResults } from "./BankImportHelper";
import ConfirmConsecutiveModel from "./ConfirmConsecutiveModel";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { toast } from "react-toastify";
const bankFileImportSchema = (intl: IntlShape) =>
  yup.object().shape({
    bank: yup
      .string()
      .label(intl.formatMessage({ id: "BANKIMPORT.BANKNAME" }))
      .required(intlRequiredMessage(intl)),
    file: yup.mixed().required(intlRequiredMessage(intl)),
  });
type Props = {
  setStep: (step: number) => void;
  setIsLoading: (loading: boolean) => void;
  setImportResults: (result: ImportResults) => void;
  lastImportDate: string;
  importCompanyData: CompnayDataInterface;
  importResults: ImportResults;
};
const Step2: FC<Props> = ({
  setStep,
  lastImportDate,
  importCompanyData,
  setIsLoading,
  setImportResults,
  importResults,
}) => {
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration.bookingYears,
    shallowEqual
  ) as BookingYear;
  const { data: { data: banks = [] } = {} } = useGetBanksQuery({});
  const banksOptions = banks.map((bank: any) => {
    return {
      value: bank.id,
      label: bank.name,
    };
  });
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [importBankData, { isLoading }] = useImportBankDataMutation();
  const intl = useIntl();
  const formRef: React.RefObject<any> = useRef();
  const fileInputRef: React.RefObject<HTMLInputElement | undefined> = useRef();

  const validationBankSchema = useDynamicValidationSchema(bankFileImportSchema);
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationBankSchema]);
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
  const onConfirmClicked = () => {
    formRef?.current?.setSubmitting(true);
    setShowConfirm(false);
    const form_data = new FormData();
    if (fileInputRef.current?.files) {
      form_data.append("file", fileInputRef.current?.files[0]);
    }
    form_data.append("companyId", importCompanyData.companyId ?? "");
    form_data.append(
      "bankCOAAccountNo",
      importCompanyData.bankCOAAccountNo ?? ""
    );
    form_data.append(
      "bankCOAAccountNoPositive",
      importCompanyData.bankCOAAccountNoPositive ?? ""
    );
    form_data.append("bookingYearsNo", selectedBookingYear.id.toString() ?? "");
    form_data.append("diaryNo", importCompanyData.diaryNo ?? "");
    form_data.append("force", "true");
    form_data.append("bank", formRef.current?.values?.bank);
    importBankData(form_data)
      .unwrap()
      .then((data) => {
        setImportResults({
          newRecords: data?.newRecords,
          warning: data?.newRecords === 0 || data?.missingDays.length > 0,
          missingDays: data?.missingDays,
          newBookingYears: data?.newBookingYears,
          usedBookingYearClosing: data?.usedBookingYearClosing,
        });
        setStep(3);
      })
      .catch((err) => {
        if (err?.data?.code === 10001) {
          toast.error(
            intl.formatMessage({ id: "BANKIMPORT.BANK_IS_INCORRECT" })
          );
        }
      })
      .finally(() => {
        formRef?.current?.setSubmitting(false);
      });
  };
  return (
    <>
      <Formik
        initialValues={{
          bank: "",
          file: "",
        }}
        validationSchema={validationBankSchema}
        innerRef={formRef}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          const form_data = new FormData();
          if (fileInputRef.current?.files) {
            form_data.append("file", fileInputRef.current?.files[0]);
          }
          form_data.append(
            "bookingYearsNo",
            selectedBookingYear?.id.toString() ?? ""
          );
          form_data.append("companyId", importCompanyData.companyId ?? "");
          form_data.append(
            "bankCOAAccountNo",
            importCompanyData.bankCOAAccountNo ?? ""
          );
          form_data.append(
            "bankCOAAccountNoPositive",
            importCompanyData.bankCOAAccountNoPositive ?? ""
          );
          form_data.append("diaryNo", importCompanyData.diaryNo ?? "");
          form_data.append("bank", values.bank);
          importBankData(form_data)
            .unwrap()
            .then((data) => {
              setImportResults({
                newRecords: data?.newRecords,
                warning: data?.newRecords === 0 || data?.missingDays.length > 0,
                missingDays: data?.missingDays,
                newBookingYears: data?.newBookingYears,
                usedBookingYearClosing: data?.usedBookingYearClosing,
              });
              setStep(3);
            })
            .catch((err: any) => {
              if (err?.data?.code === 10001) {
                toast.error(
                  intl.formatMessage({ id: "BANKIMPORT.BANK_IS_INCORRECT" })
                );
              }
              setImportResults({
                newRecords: 0,
                warning: true,
                missingDays: err?.data?.missingDays,
                code: err?.data?.code,
                newBookingYears: [],
                usedBookingYearClosing: [],
              });
              if (err?.data?.code === 1003 || err?.data?.code === 1005) {
                setShowConfirm(true);
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setTouched,
          setFieldValue,
        }) => (
          <Form>
            <div className="card-body">
              <h3>
                <span>
                  {intl.formatMessage({ id: "BANKIMPORT.LAST_IMPORT_DATE" })}:
                </span>
                <span className="ms-1 h4 text-warning">
                  {lastImportDate
                    ? moment(lastImportDate).format("yyyy-MM-DD")
                    : intl.formatMessage({ id: "BANKIMPORT.NO_IMPORTED" })}
                </span>
              </h3>
              <Field
                name="bank"
                required
                className="col-12 mb-3"
                component={FormikSelectComponent}
                options={banksOptions}
                placeholder={intl.formatMessage(
                  { id: "SELECT_PLACEHOLDER" },
                  {
                    label: intl
                      .formatMessage({
                        id: "BANKIMPORT.BANKNAME",
                      })
                      .toLocaleLowerCase(),
                  }
                )}
                hint={intl.formatMessage({ id: "BANKIMPORT.BANKNAME_HINT" })}
                label={intl.formatMessage({ id: "BANKIMPORT.BANKNAME" })}
              />

              <div className="col-md-12">
                <Field
                  className={`form-control ${getFieldClassName(
                    !!touched.file,
                    !!errors.file
                  )}`}
                  innerRef={fileInputRef}
                  type="file"
                  name="file"
                />
                <small className="h5 mt-3 text-body">
                  {intl.formatMessage({ id: "BANKIMPORT.DESCRIPTION" })}
                </small>
              </div>
            </div>
            <div className="card-footer text-end">
              <div className="d-flex">
                <button className="btn btn-link" onClick={() => setStep(1)}>
                  {intl.formatMessage({ id: "CANCEL" })}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary ms-auto"
                >
                  {intl.formatMessage({ id: "CONTINUE" })}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmConsecutiveModel
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={() => onConfirmClicked()}
        importResults={importResults}
        importCompanyData={importCompanyData}
      />
    </>
  );
};

export default Step2;
