import { useIntl } from "react-intl";
import AutomaticOffsetAccount from "./AutomaticOffsetAccount/AutomaticOffsetAccount";

const MasterTablesCard = () => {
  const intl = useIntl();
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              {/* Page pre-title */}
              <div className="page-pretitle"> </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "ASIDE.MASTERTABLES" })}
              </h2>
            </div>
            {/* Page title actions */}
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row page-body">
        <div className="col-md-6">
          <AutomaticOffsetAccount />
        </div>
      </div>
    </>
  );
};

export default MasterTablesCard;
