import React from "react";
import { useNavigate, useParams } from "react-router";

import { useIntl } from "react-intl";
import { DiariesInterface } from "../DiariesModel";
import { toast } from "react-toastify";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import DiariesForm from "../DiariesForm/DiariesForm";
import {
  useGetDiariesByIdQuery,
  useUpdateDiariesMutation,
} from "../../../app/apiService";

const DiariesEdit = () => {
  const { id } = useParams();
  const [updateDiary, { isLoading: isLoadingUpdateDiary }] =
    useUpdateDiariesMutation();
  const { data: diary, isError, isLoading } = useGetDiariesByIdQuery(id);
  const navigate = useNavigate();
  const intl = useIntl();
  const onCancel = () => {
    navigate(-1);
  };
  if (isError) {
    navigate(-1);
  }
  const onFormSubmit = (
    data: DiariesInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    updateDiary({ id, ...data })
      .unwrap()
      .then(() => {
        setSubmitting(true);
        toast.success(intl.formatMessage({ id: "DIARIES.EDITED" }));
        onCancel();
      })
      .catch((err) => {
        setSubmitting(false);
        if (err.data?.error === "Diary number is in use") {
          toast.error(
            intl.formatMessage({ id: "DIARIES.DIARY_NUMBER_IN_USE" })
          );
        } else {
          toast.error(intl.formatMessage({ id: "DIARIES.EDITED_FAILED" }));
        }
      });
  };
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.INVOICE" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "DIARIES.EDIT" })}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {(isLoading || isLoadingUpdateDiary) && <ProgressBar />}
          <DiariesForm
            isEditMode={true}
            data={diary}
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default DiariesEdit;
