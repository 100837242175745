import React from "react";
import LanguageSelector from "./LanguageSelector";
import { useIntl } from "react-intl";
import UserService from "../../../app/oauth/UserService";
const Header = () => {
  const intl = useIntl();
  const logoutClicked = () => {
    UserService.doLogout();
  };
  return (
    <header className="navbar navbar-expand-md d-none d-lg-flex d-print-none">
      <div className="container-xl">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
          aria-controls="navbar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="navbar-nav flex-row order-md-last">
          <LanguageSelector />
          <div className="nav-item dropdown">
            <a
              href="#"
              className="nav-link d-flex lh-1 text-reset p-0 show"
              data-bs-toggle="dropdown"
              aria-label="Open user menu"
              aria-expanded="true"
            >
              <span
                className="avatar avatar-sm"
                style={{
                  backgroundImage: `url(${require("../../assets/static/no_image.png")})`,
                }}
              />
              <div className="d-none d-xl-block ps-2">
                <div>
                  {UserService.getGivenName()} {UserService.getFamilyName()}
                </div>
                <div className="mt-1 small text-secondary">
                  {UserService.getUsername()}
                </div>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
              data-bs-popper="static"
            >
              <button onClick={logoutClicked} className="dropdown-item">
                {intl.formatMessage({ id: "MENU.LOGOUT" })}
              </button>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
