import React from "react";
import { useIntl } from "react-intl";

const HomePageHeader = () => {
  const intl = useIntl();
  return (
    <div className="container-xl">
      <div className="row g-2 align-items-center">
        <div className="col">
          {/* Page pre-title */}
          <div className="page-pretitle"> </div>
          <h2 className="page-title">
            {intl.formatMessage({ id: "ASIDE.HOME" })}
          </h2>
        </div>
        {/* Page title actions */}
        <div className="col-auto ms-auto d-print-none">
          <div className="btn-list"></div>
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
