import { createSlice } from "@reduxjs/toolkit";
export interface BookingYear {
  id: number;
  companyId: number;
  fromDate: Date;
  toDate: Date;
}
interface IdentityGroupsState {
  bookingYears: BookingYear | null;
}
const initialState: IdentityGroupsState = {
  bookingYears: null,
};

const administrationSlice = createSlice({
  name: "administration",
  initialState: initialState,
  reducers: {
    bookingYearsSelected: (state, action) => {
      state.bookingYears = action.payload;
    },
  },
});
export { administrationSlice };
