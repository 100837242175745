import BankImport from "../features/homepage/BankImport/BankImport";
import DataInteroperability from "../features/homepage/DataInteroperability/DataInteroperability";
import HomePageHeader from "../features/homepage/HomePageHeader/HomePageHeader";
import ReportingYear from "../features/homepage/ReportingYear/ReportingYear";
const HomePage = () => {
  return (
    <>
      <div className="page-header d-print-none">
        <HomePageHeader />
      </div>
      <div className="row page-body">
        <div className="col-md-6">
          <ReportingYear />
          <DataInteroperability />
        </div>
        <div className="col-md-6">
          <BankImport />
        </div>
      </div>
    </>
  );
};

export default HomePage;
