import React from "react";
import {
  DiariesUIProvider,
  DiariesContextInterface,
} from "../features/diaries/DiariesUiContext";
import { Route, Routes, useNavigate } from "react-router";
import { ProtectedRoute } from "../routing/ProtectedRoute";
import DiariesCreate from "../features/diaries/DiariesCreate/DiariesCreate";
import DiariesEdit from "../features/diaries/DiariesEdit/DiariesEdit";
import DiariesCard from "../features/diaries/DiariesCard";
import { ParamsHOC } from "../_panel/helpers/components/ParamsHOC";
import DiariesDelete from "../features/diaries/DiariesDelete/DiariesDelete";

const DiariesPage = () => {
  const navigate = useNavigate();
  const diariesUIEvents: DiariesContextInterface = {
    diariesDeletedClicked: function (id): void {
      navigate(`/general-ledger/diaries/${id}/delete`);
    },
    newDiariesClicked: function (): void {
      navigate("/general-ledger/diaries/new-diaries");
    },
    editDiariesClicked: function (id): void {
      navigate(`/general-ledger/diaries/${id}/edit`);
    },
    fetchDiaries: false,
  };
  return (
    <DiariesUIProvider DiariesUIEvents={diariesUIEvents}>
      <Routes>
        <Route
          path="new-diaries/*"
          element={
            <ProtectedRoute>
              <DiariesCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <DiariesEdit />
            </ProtectedRoute>
          }
        />
        <Route element={<DiariesCard />} path="*">
          <Route
            path=":id/delete"
            element={
              <ParamsHOC>
                {(match: { [key: string]: string }) => (
                  <DiariesDelete
                    show={match != null}
                    id={match?.id}
                    onHide={() => {
                      navigate("/general-ledger/diaries");
                    }}
                  />
                )}
              </ParamsHOC>
            }
          />
        </Route>
      </Routes>
    </DiariesUIProvider>
  );
};

export default DiariesPage;
