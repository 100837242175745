import React from "react";
import { useIntl } from "react-intl";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import CoaForm from "../CoaForm/CoaForm";
import { useNavigate } from "react-router";
import { CoaInterface } from "../CoaModel";
import { toast } from "react-toastify";
import { useAddNewAccountsMutation } from "../../../app/apiService";
import { ErrorType } from "../../../common/TypeHelper";

const CoaCreate = () => {
  const [addNewAccounts, { isLoading }] = useAddNewAccountsMutation();
  const navigate = useNavigate();
  const intl = useIntl();
  const data: CoaInterface = {
    number: "",
    description: "",
    type: "",
    isBalance: false,
    isSubAdm: false,
  };
  const onCancel = () => {
    navigate(-1);
  };
  const onFormSubmit = (
    data: CoaInterface,
    setSubmitting: (status: boolean) => void
  ) => {
    addNewAccounts(data)
      .unwrap()
      .then(() => {
        setSubmitting(true);
        onCancel();
        toast.success(intl.formatMessage({ id: "COA.CREATED" }));
      })
      .catch((err: any) => {
        setSubmitting(false);
        if (err?.data?.code === 10008) {
          toast.warn(
            intl.formatMessage({ id: "BOOKING.SELECT_ADMINSTRATION" })
          );
        } else if (err?.data?.error === "Account number is in use") {
          toast.error(intl.formatMessage({ id: "COA.NUMBER_IN_USE" }));
        } else {
          toast.error(intl.formatMessage({ id: "COA.CREATED_FAILED" }));
        }
      });
  };
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {intl.formatMessage({ id: "ASIDE.INVOICE" })}
              </div>
              <h2 className="page-title">
                {intl.formatMessage({ id: "COA.CREATE" })}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 page-body">
        <div className="card">
          {isLoading && <ProgressBar />}
          <CoaForm
            data={data}
            onCancel={onCancel}
            formSubmitted={onFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default CoaCreate;
