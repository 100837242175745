import React, { FC } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { checkIsActive } from "../../helpers/RouterHelpers";
interface Props {
  to: string;
  title: string;
}
const MenuItem: FC<Props> = ({ to, title }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  return (
    <Link className={`dropdown-item ${isActive ? "active" : ""}`} to={to}>
      {title}
    </Link>
  );
};

export default MenuItem;
